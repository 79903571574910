<template>
<div style="position:relative">
    <div class="container-lg py-5" style="position:relative;">
        <abs-image img="vector127.webp" size="60px" left="25%" top="13%" />
         <h3 class="mt-5">Hop In! Take a Journey with us to become a future Innovator!</h3>
        <h4>
        We are here to help and guide the students in every step they take towards their dreams.
        </h4>
    </div>
     <swiper @swiper="onSwiper" :slidesPerView="'auto'" :freeMode='true' class="mySwiper mt-5">
      <swiper-slide style="min-width:1200px">
        <img style="width:100%" src="@/assets/images/rm1.png" class='trackImg'/>
      </swiper-slide>
       <swiper-slide style="min-width:1200px">
        <img style="width:100%" src="@/assets/images/rm2.png" class='trackImg'/>
      </swiper-slide>
       <swiper-slide style="min-width:1200px">
        <img style="width:100%" src="@/assets/images/rm3.png" class='trackImg'/>
      </swiper-slide>
       <swiper-slide style="min-width:1200px">
        <img style="width:100%" src="@/assets/images/rm4.png" class='trackImg'/>
      </swiper-slide>
    </swiper>
       <img  src="@/assets/images/arrowleft.png" v-on:click='Mysyper.slidePrev()' class='arrow' style="right:120px"/>
      <img  src="@/assets/images/arrowright.png" v-on:click='Mysyper.slideNext()' class='arrow' style="right:50px"/>
</div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper-bundle.min.css';
import AbsImage from './absImage.vue';
export default {
    name:'trackRoute',
    components: {
    Swiper,
    SwiperSlide,
        AbsImage
  },
   data() {
    return {
      Mysyper:'',
    };
  },
   methods:{
      onSwiper(swiper) {
                this.Mysyper = swiper;
            },
  },
  computed:{
    grade(){
      return this.$store.state.grade;
    }
  },
  watch: {
    grade(ng){
      this.Mysyper.slideTo(ng-1);
    }
  },
}
</script>
<style scoped>
.arrow{
    height: 60px;
    position: absolute;
    cursor: pointer;
    bottom: 120px;
    z-index: 999;
}
.trackImg{
    min-width: 1200px;
    width: 100%;
}
h3 {
  font-size: 2rem;
  font-weight: 800;
  text-align: left;
  margin: 0px;
}

h4 {
  color: #828282;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 12px 0px;
}
</style>
