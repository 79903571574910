
<template>
  <div class="outer">
    <div class="main_box_course" style="padding: 15px">
      <img class="img-fluid" :src="imgPath" style="height: 250px" />
      <h3 class="corse-head">{{ text }}</h3>

      <p class="left-box">{{ grade }}</p>
      <p class="left-box">{{cdata.time}}</p>
      <p class="left-box">{{cdata.session}}</p>

      <div style="width: 100%; display: inline-flex">
        <p class="left-box" style="width: 120px; height: 90">{{cdata.liveclass}}</p>
        <p
          class="left-box"
          style="width: 30px; height: 90; background: none; line-height: 45px"
        >
          +
        </p>
        <p class="left-box" style="width: 120px; height: 90">
          {{cdata.selfclass}}
        </p>
      </div>
      <div
        class="last-box"
        style="position: relative"
        v-if="$windowWidth < 860 && !showAllColumns"
      >
        <h4 style="text-align: center; margin-top: 35px; cursor: pointer" v-on:click="showAllColumns = !showAllColumns">
          FEATURE<br />+
        </h4>
      </div>
      <div
        class="main_box_course"
        style="width: 285px"
        v-if="isAchiver && showAllColumns && $windowWidth < 860"
      >
        <div class="last-box">
          <img
            style="height: 60px; margin-top: 23px; margin-right: 30px"
            src="@/assets/images/c1.png"
          />
          <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
            <strong>Learning Outcomes</strong><br />
            {{cdata.learning}}
          </p>
        </div>
        <div class="last-box">
          <img
            style="height: 60px; margin-top: 23px; margin-right: 30px"
            src="@/assets/images/c2.png"
          />
          <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
            <strong>Skills</strong><br />
             {{cdata.skills}}
          </p>
        </div>
        <div class="last-box">
          <img
            style="height: 60px; margin-top: 23px; margin-right: 30px"
            src="@/assets/images/c3.png"
          />
          <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
            <strong>Curriculum</strong><br />
            {{cdata.Curriculum}}
          </p>
        </div>
        <div class="last-box" style="position: relative">
          <p
            style="
              width: 100px;
              font-size: 1.3rem;
              font-weight: 800;
              text-align: center;
              position: absolute;
              left: 15px;
              top: 60px;
            "
          >
            <span
              style="
                font-size: 1rem;
                font-weight: 500;
                text-decoration: line-through;
              "
              >₹{{cdata.pricecut}}</span
            >
            ₹{{cdata.price}}
          </p>
          <button
            type="button"
            class="btn btn-danger btn-lg"
            style="height: 45px; position: absolute; bottom: 15px; right: 15px"
            @click="buyCourse"
          >
            Buy Course
          </button>
        </div>
      </div>
      <div
        class="main_box_course"
        style="width: 285px"
        v-else-if="!isAchiver && showAllColumns && $windowWidth < 860"
      >
        <div class="last-box">
          <img
            style="height: 60px; margin-top: 23px; margin-right: 30px"
            src="@/assets/images/c1.png"
          />
          <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
            <strong>Learning Outcomes</strong><br />
            {{cdata.learning}}
          </p>
        </div>
        <div class="last-box">
          <img
            style="height: 60px; margin-top: 23px; margin-right: 30px"
            src="@/assets/images/c2.png"
          />
          <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
            <strong>Skills</strong><br />
            {{cdata.skills}}
          </p>
        </div>
      </div>
    </div>
    <div class="main_box_course">
      <div
        class="center-box"
        :style="[
          $windowWidth < 860
            ? { 'margin-right': '15px' }
            : { 'margin-right': '0px' },
        ]"
      >
        <img class="img-fluid" style="max-height:100%;max-width:100%" :src="require('@/assets/images/'+cdata.image1)" />
      </div>
      <div
        class="center-box"
        :style="[
          $windowWidth < 860
            ? { 'margin-right': '15px' }
            : { 'margin-right': '0px' },
        ]"
      >
        <img class="img-fluid" style="max-height:100%;max-width:100%" :src="require('@/assets/images/'+cdata.image2)" />
      </div>
      <div
        class="last-box"
        style="position: relative"
        v-if="$windowWidth < 860 && !showAllColumns"
      >
        <p
          style="
            width: 100px;
            font-size: 1.3rem;
            font-weight: 800;
            text-align: center;
            position: absolute;
            left: 15px;
            top: 60px;
          "
        >
          <span
            style="
              font-size: 1rem;
              font-weight: 500;
              text-decoration: line-through;
            "
            >₹{{cdata.pricecut}}</span
          >
          ₹{{cdata.price}}
        </p>
        <button
          type="button"
          class="btn btn-danger btn-lg"
          style="height: 45px; position: absolute; bottom: 15px; right: 15px"
          @click="buyCourse"
        >
          Buy Course
        </button>
      </div>
      <div
        class="main_box_course"
        v-if="isAchiver && showAllColumns && $windowWidth < 860"
      >
        <div class="center-box" style="margin-right: 15px;">
          <img class="img-fluid" style="max-height:100%;max-width:100%" :src="require('@/assets/images/'+cdata.image3)" />
        </div>
        <div class="center-box" style="margin-right: 15px;">
          <img class="img-fluid" style="max-height:100%;max-width:100%" :src="require('@/assets/images/'+cdata.image4)" />
        </div>
      </div>
      <div
        class="main_box_course"
        v-else-if="!isAchiver && showAllColumns && $windowWidth < 860"
      >
        <div class="last-box">
          <img
            style="height: 60px; margin-top: 23px; margin-right: 30px"
            src="@/assets/images/c3.png"
          />
          <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
            <strong>Curriculum</strong><br />
            {{cdata.Curriculum}}
          </p>
        </div>
        <div class="last-box" style="position: relative">
          <p
            style="
              width: 100px;
              font-size: 1.3rem;
              font-weight: 800;
              text-align: center;
              position: absolute;
              left: 15px;
              top: 60px;
            "
          >
            <span
              style="
                font-size: 1rem;
                font-weight: 500;
                text-decoration: line-through;
              "
              >₹{{cdata.pricecut}}</span
            >
            ₹{{cdata.price}}
          </p>
          <button
            type="button"
            class="btn btn-danger btn-lg"
            style="height: 45px; position: absolute; bottom: 15px; right: 15px"
            @click="buyCourse"
          >
            Buy Course
          </button>
        </div>
      </div>
    </div>
    <div
      class="main_box_course"
      style="width: 15px"
      v-if="isAchiver && $windowWidth > 860"
    />
    <div class="main_box_course" v-if="isAchiver && $windowWidth > 860">
      <div class="center-box">
         <img class="img-fluid" style="max-height:100%;max-width:100%" :src="require('@/assets/images/'+cdata.image3)" />
      </div>
      <div class="center-box">
         <img class="img-fluid" style="max-height:100%;max-width:100%" :src="require('@/assets/images/'+cdata.image4)" />
      </div>
    </div>
    <div
      class="main_box_course"
      style="width: 15px"
      v-if="$windowWidth > 860"
    />
    <div class="main_box_course" v-if="$windowWidth > 860">
      <div class="last-box">
        <img
          style="height: 60px; margin-top: 23px; margin-right: 30px"
          src="@/assets/images/c1.png"
        />
        <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
          <strong>Learning Outcomes</strong><br />
          {{cdata.learning}}
        </p>
      </div>
      <div class="last-box">
        <img
          style="height: 60px; margin-top: 23px; margin-right: 30px"
          src="@/assets/images/c2.png"
        />
        <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
          <strong>Skills</strong><br />
          {{cdata.skills}}
        </p>
      </div>
      <div class="last-box">
        <img
          style="height: 60px; margin-top: 23px; margin-right: 30px"
          src="@/assets/images/c3.png"
        />
        <p style="width: 165px; font-size: 0.8rem; margin-top: 12px">
          <strong>Curriculum</strong><br />
          {{cdata.Curriculum}}
        </p>
      </div>
      <div class="last-box" style="position: relative">
        <p
          style="
            width: 100px;
            font-size: 1.3rem;
            font-weight: 800;
            text-align: center;
            position: absolute;
            left: 15px;
            top: 60px;
          "
        >
          <span
            style="
              font-size: 1rem;
              font-weight: 500;
              text-decoration: line-through;
            "
            >₹{{cdata.pricecut}}</span
          >
          ₹{{cdata.price}}
        </p>
        <button
          type="button"
          class="btn btn-danger btn-lg"
          style="height: 45px; position: absolute; bottom: 15px; right: 15px"
          @click="buyCourse"
        >
          Buy Course
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "courseBox",
  props: {
    backgroundColor: {
      type: String,
    },
    textColor: {
      type: String,
    },
    img: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    grade: {
      type: String,
      required: true,
    },
    isAchiver: {
      type: Boolean,
      required: true,
    },
    cdata:{
      require:true
    },
    buyCourseMethod:{},
    buyCoursetrackId:{}
  },
  data() {
    return {
      bgColor: this.backgroundColor,
      courseNameColor: this.textColor,
      imgPath: require("@/assets/images/" + this.img),
      showExtraBox: this.isAchiver,
      showAllColumns: false,
    };
  },
  methods:{
    buyCourse(){
      this.buyCourseMethod(this.buyCoursetrackId);
    }
  }
  
};
</script>
<style scoped>
.outer {
  background: v-bind(bgColor);
  display: inline-flex;
  border-radius: 15px;
}
.left-box {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  margin: 0px;
  text-align: center;
  padding: 16px 0px;
  margin-top: 15px;
  font-weight: 700;
}
.corse-head {
  font-size: 1.5rem;
  margin: 0px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  color: v-bind(courseNameColor);
}
.center-box {
  background: #f3f7fd;
  margin: 15px 0px;
  padding: 10px;
  border-radius: 10px;
  height: 300px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}
.last-box {
  margin: 15px 15px 15px 0px;
  background: #fff;
  border-radius: 15px;
  height: 141px;
  padding: 15px;
  position: relative;
}
.last-box p {
  position: absolute;
  top: 15px;
  right: 15px;
}
.main_box_course {
  width: 300px;
}
</style>