<template>
  <div class="container-md" style="overflow: visible;">
    <div class="row pt-2 pb-0" style="position: relative;overflow: visible;" >
      <abs-image
        v-if="$windowWidth > 680"
        img="vector1.jpg"
        size="180px"
        left="35%"
        top="80%"
      />
      <div
        class="col-12"
        style="height: 220px; position: relative"
        v-if="$windowWidth > 680"
      >
        <abs-image img="vector2.webp" size="60px" left="20%" top="28%" />
        <abs-image img="vector3.jpg" size="150px" left="81%" top="20%" />
      </div>
      <div
        class="col-lg-5 col-md-12 order-lg-2 order-3 mt-5"
        style="position: relative"
      >
        <h1>Decode Logics behind Coding with Our Online Classes and Kits!</h1>
        <p>
          Self-paced Online AI/IoT/Robotics/ Machine Learning<br />
          Classes For Students Of Grades 1-12
        </p>
        <div
          class="input-group input-group-lg mb-3 mt-5" style="overflow: visible;"
          v-if="$windowWidth > 680"
        >
          <vue-tel-input
            style="background: none; border: none; box-shadow: none;width: 50%;"
            
            mode="international"
            @input="mobileNumberChange"
            :autoDefaultCountry="false"
            :defaultCountry="$store.state.country"
            :validCharactersOnly="true"
          >
          </vue-tel-input>
          <book-demobtn location="204" cls="book_btn_half" />
        </div>
        <div v-else>
          <div class="mt-3" style="overflow: visible;">
            <vue-tel-input
              style="
                background: none;
                border-radius: 0.7rem;
                border: 2px solid black;
                box-shadow: none;
              "
              class="form-control-lg"
              mode="international"
              @input="mobileNumberChange"
              :autoDefaultCountry="false"
              :defaultCountry="$store.state.country"
              :validCharactersOnly="true"
            >
            </vue-tel-input>
          </div>
          <div class="mt-2 mb-5">
            <book-demobtn location="204" cls="book_btn" />
          </div>
        </div>
      </div>
      <div
        class="col-lg-7 col-sm-12 order-lg-3 order-2"
        style="position: relative"
      >
        <img src="@/assets/images/cover.png" style="width: 100%" />
      </div>
    </div>
  </div>
</template>
<script>
import AbsImage from "./absImage.vue";
import bookDemobtn from "./bookDemobtn.vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
export default {
  name: "Cover",
  components: {
    AbsImage,
    bookDemobtn,
    VueTelInput,
  },
  methods: {
    mobileNumberChange(number, model) {
      if (model != undefined) {
        this.$store.commit("setMobileNumber", number);
      }
    },
  },
};
</script>
<style>
.vti__input {
  background: none !important;
}
</style>
<style scoped>
h1 {
  font-weight: bold;
  font-size: calc(2rem + 0.7vw);
  color: #333333;
}
p {
  color: #6f6134;
  font-size: calc(1rem + 0.2vw);
}

    .input-group-lg {
        border-radius: 0.8rem;
        border: 2px solid #f4364c;
        overflow: hidden;
    }

.book_btn {
  width: 100%;
  margin: 0px !important;
}

    .book_btn_half {
        width: 50%;
        margin: 0px !important;
        border-radius: 0.7rem;
        border: none !important;
        background-color: #f4364c;
    }
</style>