<template>
   <div class="main p-3">
     <img class='quats' src="@/assets/images/group468.png"/>
     <div class="triangle-down"/>
     <div style="height:30px"/>
     <div class="row">
       <div class="col-4 align-self-center">
         <img :src="imgPath" class="rounded-circle mx-auto d-block img-fluid imgBG" >
       </div>
       <div class="col-8 p-1 m-0">
         {{text}}
       </div>
     </div>
     </div>
</template>
<script>
export default {
  name: 'testimonialBox',
  props: {
    backgroundColor: {
      type: String
    },
    imageColor: {
      type: String
    },
    img:{
      type: String,
      required: true
    },
    text:{
      type: String,
      required: true
    },
  },
  data(){
    return {
      bgColor: this.backgroundColor,
      bgImageColor: this.imageColor,
      imgPath: require('@/assets/images/'+this.img)
    }
  }
}
</script>
<style scoped>
.quats{
  position: absolute;
    top: -45px;
    left: 115px;
}
.main{
    background : v-bind(bgColor);
    position: relative;
    width: 400px;
    min-height: 380px;
    border-radius: 40px;
    margin: 40px auto;
}
.triangle-down {
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-top: 25px solid v-bind(bgColor);
    display: inline-block;
    position: absolute;
    height: 50px;
    width: 50px;
    left: 150px;
    bottom: -50px;
}
.imgBG{
  background:v-bind(bgImageColor);
  width: 90px;
}
</style>