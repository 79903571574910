<template>
  <div class="container-md my-5 py-5">
      <h3 class="pb-5">With Us, Complete Learning Is Ensured!</h3>
    <swiper @swiper="onSwiper" :slidesPerView="'auto'" :freeMode='true' class="mySwiper">
      <swiper-slide style="min-width: 1250px">
        <div class="row pt-5">
          <div class="col blue" v-bind:class="[d.class]" v-for="d in dataList" :key="d.image">
            <abs-image :img="d.image" size="54%" left="23%" top="-15%" />
            <h4 ><span v-html="d.head"></span></h4>
            <p>{{d.body}}</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
import AbsImage from "./absImage.vue";
export default {
  name: "withuS",
  components: {
    Swiper,
    SwiperSlide,
    AbsImage,
  },
  data() {
    return {
      dataList:[
        {
          image:'g1.gif',
          head:'Instructor Led<br />(Live Classes)',
          body:'Our instructors teach the concepts of AI, IoT, and robotics through live coding classes blended with STEM kits!',
          class:'yellow'
        },
         {
          image:'g4.gif',
          head:'Practical Application',
          body:'Our STEM kits provide a real-life tinkering experience for kids to understand the concepts and their applications.',
          class:'blue'
        },
         {
          image:'g3.gif',
          head:'Revision<br />(Video + Quiz)',
          body:'We offer revision videos along with exciting quizzes and assignments to evaluate your kids performance after each class.',
          class:'yellow'
        },
         {
          image:'g2.gif',
          head:'Peer Learning',
          body:"Get a lifetime membership of our global community on our app Let's Tinker & get inspiration from your peers all around the globe!",
          class:'blue'
        }
      ]
    };
  },
};
</script>
<style scoped>
.blue {
  background: #80cacf;
  color: #333333;
}

.yellow {
  background: #fccc38;
  color: #000;
}

.blue,
.yellow {
  border-radius: 20px;
  min-height: 500px;
  margin: 15px;
  padding: 25px;
  padding-top: 300px;
  position: relative;
  margin-top: 50px;
}

h4 {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  margin: 0px;
}
p {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  margin: 0px;
  color: #353838;
  margin-top: 15px;
}
h3 {
  font-size: 2rem;
  font-weight: 800;
  text-align: left;
  margin: 0px;
}
</style>