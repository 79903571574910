<template>
  <div class="row">
      <div class="col-12 mb-4">
        <h3>{{ head }}</h3>
        <h4>{{ subHead }}</h4>
      </div>
    </div>
    <swiper @swiper="onSwiper" :slidesPerView="'auto'" :spaceBetween="30" class="mySwiper">
      <swiper-slide style="width:400px" v-for="t in teacherList" :key="t.name">
        <div class="box">
          <div class="box-gray">
            <div class="profile-outer" />
            <div class="profile-outer-bottom" />
            <img class="rounded-circle img-fluid imgBG1" :src="require('@/assets/images/'+t.image)" />
             <h5>{{t.name}}</h5>
            <p>{{t.discription}}</p>
          </div>
        </div>
      </swiper-slide >
     
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
export default {
  name: "teacherBox",
    components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    teachers: Array,
    head: String,
    subHead: String,
  },
  data() {
    return {
      teacherList:[
        {
          name:'Kapil  Arya',
          image:'t_kapil.png',
          discription:'An IIT Delhi Graduate, with 6+ years of experience in education research, curriculum and training. Mentored students of 100+ schools for Innovation.'
        },
        {
            name:'Jahnavi. M',
            image:'t_jahnavi.jpg',
            discription: "A STEM Educator & Manager pursuing a Bachelor's in Electronics & Instrumentation Engineering from a reputed University with 5+ years of experience in educating children in various globalized Edtech platforms."
        },
        {
          name:'Siddharth Soni',
          image:'t_sid.png',
          discription:'4+ years of experience in educational research and curriculum development after receiving a bachelor\'s degree in computer science from a reputable university.'
        }
      ]
    };
  },
};
</script>
<style scoped>
.box {
  width: 400px;
  min-height: 400px;
  padding-top: 60px;
  margin: auto;
}
    .imgBG1 {
        height: 180px;
        width: 180px;
        background: #80cacf;
        border-radius: 50%;
        position: absolute;
        top: -40px;
        left: 110px;
    }
    .box-gray {
        width: 400px;
        min-height: 350px;
        background: #f2f2f2;
        border-radius: 25px;
        position: relative;
        padding-top: 165px;
    }
.profile-outer {
  height: 180px;
  width: 180px;
  background: #80cacf;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: 110px;
}
.profile-outer-bottom {
  height: 90px;
  width: 140px;
  background: #f2f2f2;
  border-radius: 50%;
  position: absolute;
  top: 55px;
  left: 130px;
}
.imgBG {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 155px;
  top: -38px;
}

h3 {
  font-size: 2rem;
  font-weight: 800;
  margin: 0px;
}

h4 {
  color: #828282;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 12px 0px;
}

h5 {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  margin: 0px;
  color: #6F6F6F;
}
p {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  margin: 0px;
  color: #6F6F6F;
  margin-top: 15px;
}
</style>