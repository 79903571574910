<template>
  <div class="container-lg mb-5">
    <swiper @swiper="onSwiper" :slidesPerView="'auto'" :freeMode='true' class="mySwiper">
      <swiper-slide style="min-width: 1250px">
        <div class="row">
          <div class="col blue">
            <abs-image img="group248.jpg" size="70%" left="15%" top="-10%"/>
            <h4>Become a<br />Problem-solver</h4>
          </div>
          <div class="col yellow">
              <abs-image img="group151.jpg"  size="54%" left="23%" top="-15%"/>
            <h4>Develop<br />Resilience</h4>
          </div>
          <div class="col blue">
              <abs-image img="group148.jpg"  size="46%" left="27%" top="-15%"/>
            <h4>Promotes Logical<br />Reasoning</h4>
          </div>
          <div class="col yellow">
              <abs-image img="group162.jpg"  size="70%" left="15%" top="-10%"/>
            <h4>Enhance<br />Creativity</h4>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper-bundle.min.css';
import AbsImage from './absImage.vue';
export default {
    name:'tagLines',
    components: {
    Swiper,
    SwiperSlide,
        AbsImage,
  },
  setup() {
    return {
     
    };
  },
}
</script>
<style scoped>
.blue {
  background: #80cacf;
  color: #ffffff;
}

.yellow {
  background: #fccc38;
  color: #333333;
}

.blue,
.yellow {
  border-radius: 20px;
  min-height: 100px;
  margin: 15px;
  padding: 25px;
  padding-top: 230px;
  position: relative;
  margin-top: 50px;
}

h4 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
  margin: 0px;
}
</style>