<template>
  <div class="row mb-5">
    <div class="col-md-6">
      <h4>Select Grade</h4>
      <div class="radio-toolbar btn-grade">
        <div style="display: inline">
          <input
            id="grade1"
            v-on:click="setGrade('1 to 3 Grade', 1)"
            checked
            type="radio"
            name="grade"
            value="1"
          />
          <label for="grade1">1 to 3</label>
        </div>
        <div style="display: inline">
          <input
            id="grade2"
            v-on:click="setGrade('4 to 6 Grade', 2)"
            type="radio"
            name="grade"
            value="2"
          />
          <label for="grade2">4 to 6</label>
        </div>
        <div style="display: inline">
          <input
            id="grade3"
            v-on:click="setGrade('7 to 8 Grade', 3)"
            type="radio"
            name="grade"
            value="3"
          />
          <label for="grade3">7 to 8</label>
        </div>
        <div style="display: inline">
          <input
            id="grade4"
            v-on:click="setGrade('8+ Grade', 4)"
            type="radio"
            name="grade"
            value="4"
          />
          <label for="grade4">8+</label>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4>Select Course Mode</h4>
      <div class="radio-toolbar btn-mode">
        <div style="display: inline">
          <input id="mode1" @click="setMode(1)" checked type="radio" name="mode" value="1" />
          <label for="mode1">1:1 Classes</label>
        </div>
        <div style="display: inline">
          <input id="mode2" type="radio" @click="setMode(2)" name="mode" value="2" />
          <label for="mode2">1:15 Classes</label>
        </div>
      </div>
    </div>
  </div>
  <h3 class="m-0 mb-4 mt-5">Curriculum For Future Innovators!</h3>
  <swiper
    @swiper="onSwiper"
    :slidesPerView="'auto'"
    :spaceBetween="10"
    class="mySwiper mb-5"
    :freeMode="true"
  >
    <swiper-slide style="max-width: 1230px; width: auto">
      <course-box
        backgroundColor="#FCCC38"
        textColor="#000"
        img="1-3-ach.png"
        text="ACHIEVER"
        :grade="grade"
        :isAchiver="true"
        :cdata="cData.Achiever"
        :buyCourseMethod="buyCourseMethod"
        :buyCoursetrackId="buyCoursetrackId"
      />
    </swiper-slide>
    <swiper-slide style="max-width: 1230px; width: auto">
      <course-box
        backgroundColor="#80CACF"
        textColor="#fff"
        img="1-3-lrn.png"
        text="LEARNER"
        :grade="grade"
        :isAchiver="false"
        :cdata="cData.Learner"
        :buyCourseMethod="buyCourseMethod"
        :buyCoursetrackId="buyCoursetrackId"
      />
    </swiper-slide>
    <swiper-slide style="max-width: 1230px; width: auto" v-if="mode==1">
      <course-box
        backgroundColor="#FCCC38"
        textColor="#000"
        img="1-3-exp.png"
        text="EXPLORER"
        :grade="grade"
        :isAchiver="false"
        :cdata="cData.Explorer"
        :buyCourseMethod="buyCourseMethod"
        :buyCoursetrackId="buyCoursetrackId"
      />
    </swiper-slide>
  </swiper>
  <div style="width: 100%; position: relative; height: 30px">
    <img
      src="@/assets/images/arrowleft.png"
      v-on:click="Mysyper.slidePrev()"
      class="arrow"
      style="right: 120px"
    />
    <img
      src="@/assets/images/arrowright.png"
      v-on:click="Mysyper.slideNext()"
      class="arrow"
      style="right: 50px"
    />
  </div>
  <p style="text-align: center" class="mt-5 pt-5">
    <book-demobtn location="205" />
    <span
      style="height: 20px; width: 20px; display: block"
      v-if="$windowWidth < 680"
    ></span>
    <Btn_outlineBlack DisplayText="Download Curriculum" @click="onDownloadPdf" />
  </p>
</template>
<script>
import courses from "@/assets/jsons/courses.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
import courseBox from "./courseBox.vue";
import bookDemobtn from "./bookDemobtn.vue";
import Btn_outlineBlack from "./btn_outlineBlack.vue";
import {trackerLeadSquaredEvent,trackerFBPixel} from '@/assets/js/TinkerlyTracking.js';
export default {
  name: "course",
  components: {
    Swiper,
    SwiperSlide,
    courseBox,
    bookDemobtn,
    Btn_outlineBlack,
  },
  props:['buyCourseMethod','buyCoursetrackId'],
  data() {
    return {
      grade: "1 - 3 Grade",
      Mysyper: "",
      gradeValue:1,
      mode:1,
      cData : courses[0][0],
       pdfName:{
                    m1g1:"class 1-3 for 1 to 1.pdf",
                    m1g2:"class 4-6 for 1 to 1.pdf",
                    m1g3:"class 7-8 for 1 to 1.pdf",
                    m1g4:"class 8 for 1 to 1.pdf",
                    m2g1:"class 1-3 for 1 to 5.pdf",
                    m2g2:"class 4-6 for 1 to 5.pdf",
                    m2g3:"class 7-8 for 1 to 5.pdf",
                    m2g4:"class 8 for 1 to 5.pdf",
                },
    };
  },
  methods: {
    onSwiper(swiper) {
      this.Mysyper = swiper;
    },
    setMode(mode) {
      this.mode = mode;
      this.$store.commit("setMode", mode);
      this.cData = courses[this.gradeValue-1][this.mode-1];
    },
    setGrade(text, grade) {
      this.$store.commit("setGrade", grade);
      this.grade = text;
      this.gradeValue = grade;
      this.cData = courses[this.gradeValue-1][this.mode-1];
    },
    onDownloadPdf(){
      trackerLeadSquaredEvent(206);
       trackerFBPixel('Download');
     this.$gtag.event("conversion", {
              send_to: "AW-810557807/I6CpCJXGgYYDEO_CwIID",
            });
                var key = "m"+this.mode+"g"+this.gradeValue;
                window.open("/pdf/"+this.pdfName[key], '_blank').focus();
    }
  },
};
</script>
<style scoped>
.arrow {
  height: 60px;
  position: absolute;
  cursor: pointer;
}
.radio-toolbar input[type="radio"],
.radio-toolbar input[type="checkbox"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  padding: 10px 15px;
  font-family: sans-serif, Arial;
  font-size: 1.3rem;
  color: #000;
  cursor: pointer;
  min-height: 45px;
  min-width: 100px;
  text-align: center;
  border: 2px solid black;
  margin: 0px 5px;
  border-radius: 12px;
  font-weight: 700;
  margin-bottom: 10px;
}

.btn-mode label:hover {
  background-color: #fccc38;
  color: #fff;
}

.btn-mode input[type="radio"]:checked + label,
.btn-mode input[type="checkbox"]:checked + label {
  background-color: #fccc38;
  color: #fff;
}

.btn-grade label:hover {
  background-color: #80cacf;
  color: #fff;
}

.btn-grade input[type="radio"]:checked + label,
.btn-grade input[type="checkbox"]:checked + label {
  background-color: #80cacf;
  color: #fff;
}
</style>