<template>
  <footer class="pt-4 pl-4 pb-4" style="background: #fccc38">
    <div class="container-lg">
      <div class="row m-0 justify-content-between" style="border-bottom:1px solid black">
          <div class="col-md-5 col-sm-12 pt-3">
              <a href="https://code.tinker.ly/">
                  <img src="@/assets/india/image/logo.png"
                       style="max-width: 250px"
                       class="img-fluid" /></a>
                  <p style="font-size: 1.1em;font-weight: 500" class="mt-5">
                      An initiative by IIT-D & XLRI Alumni to promote Coding Skills & STEM
                      education among young minds. It consists of superior pedagogy,
                      blended learning, and a play-based curriculum to enable interactive
                      learning for the students of grades 1-12.
                  </p>
          </div>
        <div class="col-md-7 col-sm-12  pt-3">
          <div class="footer-menu">
            <div class="menu-footer-menu-container-lg">
                <ul class="menu-list">
                    <li class="menu-item "><a title="Let'sTinker'" target="_blank" href="https://alpha.letstinker.tinker.ly/" class="nav-link" itemprop="url"><span itemprop="name">Let'sTinker</span></a></li>
                    <li class="menu-item menu-item-link">
                        <a title="About Us"
                           target="_blank"
                           href="https://tinker.ly/about-us/"
                           @click="navigateToPageWithTracking(208, null)"
                           class="nav-link"
                           itemprop="url"><span itemprop="name">About Us</span></a>
                    </li>
                    <li class="menu-item menu-item-link">
                        <a title="Contact us"
                           target="_blank"
                           href="https://tinker.ly/contact-us/"
                           @click="navigateToPageWithTracking(209, null)"
                           class="nav-link"
                           itemprop="url"><span itemprop="name">Contact us</span></a>
                    </li>

                    <li class="menu-item menu-item-link">
                        <a title="Partner with us"
                           target="_blank"
                           href="https://tinker.ly/partner/"
                           class="nav-link"
                           itemprop="url"><span itemprop="name">Partner with us</span></a>
                    </li>
                    <li class="menu-item menu-item-link">
                        <a title="Blog"
                           target="_blank"
                           href="https://tinker.ly/blog/"
                           class="nav-link"
                           @click="navigateToPageWithTracking(210, null)"
                           itemprop="url"><span itemprop="name">Blog</span></a>
                    </li>
                    <li class="menu-item menu-item-link">
                        <a title="Become a Teacher"
                           target="_blank"
                           href="https://tinker.ly/join-tinkerlys-teacher-program-to-enlighten-young-minds-about-stem-coding/"
                           class="nav-link"
                           itemprop="url"><span itemprop="name">Become a Teacher</span></a>
                    </li>
                </ul>
            </div>
          </div>
          <p class="mt-5 text-right" style="color: black; font-size: 1.2em;font-weight: 500">
                Follow us on Social Media to stay connected <br/>and receive instant updates
              </p>
              <ul class="menu-list text-right">
                <li
                  class="menu-item"
                  style="font-size: 2em; font-weight: 900; margin-right: 25px"
                >
                  <a
                    href="https://www.instagram.com/tinkerlygroup/"
                    target="_blank"
                    ><img
                      style="height: 35px"
                      src="@/assets/svg/instagram-brands.svg"
                  /></a>
                </li>

                <li
                  class="menu-item"
                  style="font-size: 2em; font-weight: 900; margin-right: 25px"
                >
                  <a
                    href="https://www.facebook.com/tinkerlygroup/"
                    target="_blank"
                    ><img
                      style="height: 35px"
                      src="@/assets/svg/facebook-square-brands.svg"
                  /></a>
                </li>

                <li
                  class="menu-item"
                  style="font-size: 2em; font-weight: 900; margin-right: 25px"
                >
                  <a
                    href="https://www.youtube.com/channel/UCJccc_N6nxOtOd_2r2OCRRg"
                    target="_blank"
                    ><img
                      style="height: 35px"
                      src="@/assets/svg/youtube-brands.svg"
                  /></a>
                </li>

                <li
                  class="menu-item"
                  style="font-size: 2em; font-weight: 900; margin-right: 0px"
                >
                  <a
                    href="https://www.linkedin.com/company/tinkerlygroup/"
                    target="_blank"
                    ><img
                      style="height: 35px"
                      src="@/assets/svg/linkedin-brands.svg"
                  /></a>
                </li>
              </ul>
        </div>
        
      </div>
      <div class="row pt-4 justify-content-between">
          <div class="col-md-6 col-sm-12">
            <span class="base-footer-left-top-border"></span>
            <p style="font-size: 0.9em">
              Copyright © Elation Edtech Pvt Ltd 2021. All Rights Reserved
            </p>
          </div>
          <div class="col-md-6 col-sm-12 base-footer-right-links">
            <ul class="menu-list">
              <li class="menu-item" >
                <a
                  style="font-size: 0.9em;margin-right: 25px;padding-right:25px;border-right: 1px solid black;"
                  href="https://tinker.ly/privacy-policy-for-tinkerly/"
                  target="_blank"
                >
                  Privacy Policy</a
                >
              </li>
              <li class="menu-item">
                <a
                  style="font-size: 0.9em"
                  href="https://tinker.ly/terms-and-conditions/"
                  target="_blank"
                  >Terms & Conditions</a
                >
              </li>
              <li class="menu-item">
                <a
                  style="font-size: 0.9em; margin-left: 25px;padding-left:25px;border-left: 1px solid black;"
                  href="https://tinker.ly/guidelines-for-setting-up-of-atal-tinkering-laboratories/"
                  target="_blank"
                  >Guidelines for ATL</a
                >
              </li>
            </ul>
          </div>
        </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "footer",
};
</script>
<style scoped>
.menu-item {
  list-style-type: none;
  display: inline-block;
  color: #000;
}
.menu-list {
  list-style-type: none;
}
.nav-link {
  color: #000;
  padding-right: 0px;
  font-weight: 600;
    font-size: 1.1rem;
}

.menu-item > a{
    color: #000;
}
.footer-menu{
  text-align: right;
}

.text-right{
  text-align: right;
}
@media screen  and (max-width: 860px){
  .menu-item-link{
display: list-item;
  }
}

@media screen and (max-width: 768px) {
  .footer-menu{
  text-align: left;
}
.menu-item > a{
  padding-left: 0px;
}
ul{
  padding-left: 0px;
}
.text-right{
  text-align: left;
}
}

</style>
